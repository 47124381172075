<template>
  <iframe
    src="https://p.datadoghq.com/sb/ac9680200-6fd6e1607e292d3b62f4c7d04b1f95e1"
    style="left:0px;margin:0px;padding:0px;position:absolute;overflow:hidden;height:100%;width:100%"
    height="100%"
    width="100%"
  ></iframe>
</template>

<script>
export default {
  name: "Dashboard",

  data() {
    return {
      tools: [],
    };
  },

  methods: {
    toggleBodyClass(addRemoveClass, className) {
      const el = document.body;

      if (addRemoveClass === "addClass") {
        el.classList.add(className);
      } else {
        el.classList.remove(className);
      }
    },
  },
  mounted() {
    this.toggleBodyClass("addClass", "hidden-overflow");
  },
  destroyed() {
    this.toggleBodyClass("removeClass", "hidden-overflow");
  },
};
</script>
